





































import Vue from "vue";

import { UserCreate } from "@/assets/User";
import ButtonComponent from "@/components/form/ButtonComponent.vue";
import InputText from "@/components/form/InputText.vue";

const Connexion = Vue.extend({
  name: "Connexion",
  components: {
    ButtonComponent,
    InputText
  },

  data() {
    return {
      user: new UserCreate(),
      message: ""
    };
  },

  computed: {
    loggedIn() {
      return this.$store.state.auth.loggedIn;
    },
    loginExpired() {
      return this.$store.state.auth.expired;
    }
  },

  mounted() {
    if (this.loggedIn) {
      // connecté, mais expiré
      if (this.loginExpired)
        this.message = "Votre session a expirée. Veuillez vous reconnecter.";
      // redirection si déjà connecté
      else this.$router.replace("/profil");
    }
  },

  methods: {
    submitHandler() {
      const username = (document.getElementById("pseudo") as HTMLInputElement)
        .value;
      const password = (document.getElementById("password") as HTMLInputElement)
        .value;

      this.user.username = username;
      this.user.password = password;

      // envoi des identifiants & récupération du token
      this.$store
        .dispatch("auth/login", this.user)
        .then(() => {
          this.$router.push("/profil");
        })
        .catch(error => {
          if (error.response) this.message = error.response.data.detail;
          else console.error(error);
        });
    }
  }
});
export default Connexion;
