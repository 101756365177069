


























import Vue from "vue";
export default Vue.extend({
  name: "InputText",

  props: {
    name: {
      type: String,
      default: "input",
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "text"
    },
    minLength: {
      type: Number,
      default: 0
    },
    maxLength: {
      type: Number,
      default: 255
    },
    minValue: {
      type: Number,
      default: 0
    },
    maxValue: {
      type: Number,
      default: 255
    },
    value: {
      type: String,
      default: null
    },
    inline: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    flexDirection(): string {
      return this.inline ? "flex-row" : "flex-col";
    },
    width(): string {
      return this.inline ? "w-20" : "self-stretch";
    },
    errorBorder(): string {
      return this.error ? "border-danger" : "border-dark";
    },
    errorText(): string {
      return this.error ? "text-danger" : "text-dark";
    }
  },

  methods: {
    inputHandler(e: Event) {
      const target = e.target as HTMLInputElement;
      this.$emit("input", target.value + "");
    }
  }
});
