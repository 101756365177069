




































































import Vue from "vue";

import { UserCreate } from "@/assets/User";
import ButtonComponent from "@/components/form/ButtonComponent.vue";
import InputText from "@/components/form/InputText.vue";

const Inscription = Vue.extend({
  components: {
    ButtonComponent,
    InputText
  },

  data() {
    return {
      user: new UserCreate(),
      message: ""
    };
  },

  computed: {
    loggedIn() {
      return this.$store.state.auth.loggedIn;
    }
  },

  // redirection si déjà connecté
  created() {
    if (this.loggedIn) this.$router.push("/profil");
  },

  methods: {
    submitHandler() {
      // validation
      let error = false;
      const username = document.getElementById("username") as HTMLInputElement;
      const email = document.getElementById("email") as HTMLInputElement;
      const password = document.getElementById("password") as HTMLInputElement;
      const passwordConfirm = document.getElementById(
        "password-confirm"
      ) as HTMLInputElement;

      if (password.value !== passwordConfirm.value) {
        error = true;
        password.classList.add("bg-danger");
        passwordConfirm.classList.add("bg-danger");
      }

      if (!error) {
        password.classList.remove("bg-danger");
        passwordConfirm.classList.remove("bg-danger");

        // inscription
        this.user.username = username.value;
        this.user.password = password.value;
        this.user.email = email.value;

        this.$store
          .dispatch("auth/register", this.user)
          .then(() => {
            this.$router.push("/connexion");
          })
          .catch(error => (this.message = error.response.data.detail));
      }
    }
  }
});
export default Inscription;
